import { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import { CourseComponents, Footer, Navbar, Playlist } from "../../../components";
import { useStore } from "../../../store";

function Course() {
    const params = useParams();
    const { courseStore, userStore } = useStore();
    const course: any = courseStore.courseDetails;

    useEffect(() => {
        window.scrollTo(0, 0);
        if (params.id) {
            courseStore.getCourseById(String(params.id));
        }
        userStore.getUser();
    }, []);

    return (
        <>
            <Navbar />

            <CourseComponents.CourseBreadcrumbs type="course" />

            <CourseComponents.CourseHeader type="course" />
            <CourseComponents.CourseStats />
            <CourseComponents.CourseNavBar type="course" />

            <CourseComponents.AboutCourse type="course" />
            <CourseComponents.CourseContent />

            {course?.tutors ? (
                <CourseComponents.AboutInstructor type="course" />
            ) : null}
            
            <CourseComponents.CourseUpcomingCohorts courseId={course?.id} />
            <CourseComponents.CourseStories type="course" />
            <CourseComponents.FAQs />

            <Footer />
        </>
    );
}

export default observer(Course);