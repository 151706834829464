import axios from "axios";
import axiosInstance from "../config/api";
import { makeAutoObservable, runInAction } from "mobx";

export class CourseStore {
  isNew = false;
  domains = [];
  categories = [];
  instructors = [];
  instructorDetails: any = null;
  courseDetails = {};
  filter = {
    search: "",
    priceMax: 0,
    location: "",
    categoryId: "",
  };
  searchedCourses = [];
  byDomainCourses = [];
  topRatedCourses = [];
  upcomingSessions = [];
  recommendedSessions = [];
  subscribedWithCoupon = false;
  selectedCourse: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setInitialState() {
    this.isNew = false;
    this.domains = [];
    this.categories = [];
    this.instructors = [];
    this.courseDetails = {};
    this.searchedCourses = [];
    this.byDomainCourses = [];
    this.topRatedCourses = [];
    this.upcomingSessions = [];
    this.recommendedSessions = [];
    this.selectedCourse = null;
    this.instructorDetails = null;
    this.subscribedWithCoupon = false;
    this.filter = {
      search: "",
      priceMax: 0,
      location: "",
      categoryId: "",
    };
  }

  getDomains() {
    axiosInstance
      .get("/Domains")
      .then((response: { data: never[] }) => {
        runInAction(() => {
          this.domains = response.data;
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getCategories() {
    axiosInstance
      .get("/Categories")
      .then((response: { data: never[] }) => {
        runInAction(() => {
          this.categories = response.data;
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getInstructors(limit: number) {
    axiosInstance
      .get(`/Profiles/topInstructorsList?limit=${limit}`)
      .then((response: { data: { data: never[] } }) => {
        runInAction(() => {
          this.instructors = response.data.data;
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getInstructorById(id: string) {
    axiosInstance
      .get(`/Profiles/instructorDetails/${id}`)
      .then((response: { data: { data: any } }) => {
        runInAction(() => {
          this.instructorDetails = response.data;
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  async searchCourses(
    limit: number,
    data: {
      search?: string;
      priceMax?: number;
      location?: string;
      categoryId?: string;
    }
  ) {
    const content: any = {};
    if (data.search) {
      content.keyword = data.search;
    }
    if (data.categoryId) {
      content.domain = data.categoryId;
    }
    if (data.location) {
      content.location = data.location;
    }
    if (data.priceMax) {
      content.price = {
        priceMin: 0,
        priceMax: data.priceMax,
      };
    }

    const response = await axiosInstance.get("/Sessions/filter", {
      params: {
        limit: limit,
        skip: 0,
        tab: 0,
        content: JSON.stringify(content),
      },
    });
    if (response.status === 200) {
      runInAction(() => {
        this.isNew = true;
        this.searchedCourses = response.data.sessions;
        this.filter = {
          search: data.search || "",
          priceMax: data.priceMax || 0,
          location: data.location || "",
          categoryId: data.categoryId || "",
        };
      });
    }
  }

  async lookForSessions(course: string) {
    const token = localStorage.getItem("token");
    const response = await axiosInstance.get(
      `/Courses/lookForSessions/${course}??limit=6&skip=0&filters=%7B%7D&id=${course}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      runInAction(() => {
        this.isNew = true;
        this.searchedCourses = response.data.data;
        this.filter = {
          search: "",
          priceMax: 0,
          location: "",
          categoryId: "",
        };
      });
    }
  }

  getByDomainCourses() {
    axiosInstance
      .get(`/Sessions/byDomain`)
      .then((response: { data: never[] }) => {
        runInAction(() => {
          this.byDomainCourses = response.data;
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getTopRatedCourses() {
    axiosInstance
      .get(`/Courses/topRated?limit=4`)
      .then((response: { data: { data: never[] } }) => {
        runInAction(() => {
          this.topRatedCourses = response.data.data;
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getUpcomingSessions() {
    axiosInstance
      .get(`/Sessions/upcomingSessions?tab=0`)
      .then((response: { data: { data: never[] } }) => {
        runInAction(() => {
          this.upcomingSessions = response.data.data;
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getRecommendedSessions() {
    const token = localStorage.getItem("token");
    if (token) {
      axiosInstance
        .get(`/Sessions/recommendedSessions?limit=7`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response: { data: { data: never[] } }) => {
          runInAction(() => {
            this.recommendedSessions = response.data.data;
          });
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  getCourseById(id: string) {
    axiosInstance
      .get(`/Courses/details/${id}`)
      .then((response: { status: number; data: any }) => {
        if (response.status === 200) {
          runInAction(() => {
            this.courseDetails = response.data;
          });
          if (response.data?.tutors?.id) {
            this.getInstructorById(response.data.tutors.id);
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getCohortById(id: string) {
    axiosInstance
      .get(`/Sessions/details/${id}`)
      .then((response: { status: number; data: any }) => {
        if (response.status === 200) {
          runInAction(() => {
            this.courseDetails = response.data;
          });
          const tutorId: string | undefined =
            response.data?.sessionCourse?.tutors?.id;
          if (tutorId) {
            this.getInstructorById(tutorId);
          } else {
            runInAction(() => {
              this.instructorDetails = null;
            });
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  selectCourse(course: string) {
    runInAction(() => {
      this.selectedCourse = course;
    });
  }

  async subscribeWithCoupon(data: {
    code: string;
    studentId: string;
    sessionId: string;
  }): Promise<boolean> {
    axiosInstance
      .post(
        `Coupons/subscribeWithCoupon?code=${data.code}&studentId=${data.studentId}&sessionId=${data.sessionId}`
      )
      .then((response: { status: number }) => {
        if (response.status === 200) {
          this.subscribedWithCoupon = true;
          return true;
        }
      })
      .catch((error: any) => {
        console.error(error);
        return false;
      });
    return false;
  }

  async payForCourse(data: {
    courseId: string;
    paymentMethod: "PostWire" | "Onsite";
    profileId: string;
    phoneNumber: string;
    address: string;
    userToken: string;
  }): Promise<boolean> {
    axiosInstance
      .post(
        "TrackingForms",
        {
          address: data.address,
          paymentMethod: data.paymentMethod,
          phone: data.phoneNumber,
          profileId: data.profileId,
          sessionId: data.courseId,
          step: 2,
        },
        {
          headers: {
            Authorization: data.userToken,
          },
        }
      )
      .then((response: { status: number }) => {
        if (response.status === 200) {
          return true;
        }
      })
      .catch((error: any) => {
        console.error(error);
        return false;
      });
    return false;
  }

  async getMyCourses(
    id: string,
    limit: number,
    skip: number,
    tab: number
  ): Promise<any | null> {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(
        `/Courses/getListCourses/${id}?limit=${limit}&skip=${skip}&tab=${tab}&id=${id}&access_token=${token}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getAllDomains(): Promise<any | null> {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(`/Domains`);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getAllCategories(): Promise<any | null> {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(`/Categories`);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async newCourse(course: object): Promise<any | null> {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        `/Courses/newCourse?access_token=${token}`,
        course
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async addCourseToFavorites(
    courseId: string,
    profileId: string
  ): Promise<any | null> {
    const token = localStorage.getItem("token");
    let body = {
      courseId,
      profileId,
    };
    try {
      const response = await axiosInstance.post(
        `/FavoriteCourses?access_token=${token}`,
        body
      );

      return response.data;
    } catch (error) {
      console.log("errorr", error);
      return null;
    }
  }

  async addSessionToFavorites(
    sessionId: string,
    profileId: string
  ): Promise<any | null> {
    const token = localStorage.getItem("token");
    let body = {
      sessionId,
      profileId,
    };

    try {
      const response = await axiosInstance.post(
        `/FavoriteSessions?access_token=${token}`,
        body
      );

      return response.data;
    } catch (error) {
      console.log("errorr", error);
      return null;
    }
  }

  async deleteCourseFromFavorites(
    favoriteCourseId: string
  ): Promise<any | null> {
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.delete(
        `/FavoriteCourses/${favoriteCourseId}?access_token=${token}`
      );
      return response.data;
    } catch (error) {
      console.log("errorr", error);
      return null;
    }
  }

  async deleteSessionFromFavorites(
    favoriteSessionId: string
  ): Promise<any | null> {
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.delete(
        `/FavoriteSessions/${favoriteSessionId}?access_token=${token}`
      );
      return response.data;
    } catch (error) {
      console.log("errorr", error);
      return null;
    }
  }

  async downloadSyllabus(fileUrl: string): Promise<any | null> {
    try {
      const response = await axiosInstance.get(fileUrl, {
        responseType: "blob",
      });
      return { error: false, data: response };
    } catch (error: any) {
      return {
        error: true,
        data: { code: error.code, message: error.message },
      };
    }
  }
}
