import { observer } from "mobx-react";

import { useStore } from "../../../../store";
import { Button, Popup } from "../../../../components";
import styles from "./about.module.css";
import { useState } from "react";
import env from "../../../../config/env";
import { ToastContainer, toast } from "react-toastify";

interface IProps {
  type: "course" | "cohort";
}

function AboutCourse({ type }: IProps) {
  const { courseStore } = useStore();
  const { userStore } = useStore();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const course: any = courseStore.courseDetails;

  const downloadSyllabus = async () => {
    if (userStore.user) {
      let syllabusUrl = `${env.API}/Container2s/file/download/${course.file.name}`;

      const response = await courseStore.downloadSyllabus(syllabusUrl);

      if (response.error) {
        if (response.data.code === "ERR_BAD_REQUEST") {
          toast.error("File not found.");
        } else {
          toast.error("An error has occured. Please try again later.");
        }
      } else {
        const blob = await response.data.data;

        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = course.file.name;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className={styles.about_course} id="about-course">
      <ToastContainer theme="dark" />
      <div className={styles.about_course_content}>
        <h3 className={styles.about_course_title}>About course</h3>

        <div className="w-full flex flex-col md:flex-row justify-between items-start">
          <div className={styles.about_course_details}>
            <h4>{course?.title}</h4>
            <h5>Details:</h5>
            <p>
              {course?.sessionCourse
                ? course?.description
                : course?.detailedDescription}
            </p>
          </div>

          <div className={styles.about_course_list}>
            <div className={styles.about_course_list_item}>
              <div className={styles.about_course_list_item_icon}>
                <img
                  width={24}
                  height={24}
                  alt="list icon"
                  src="/images/icons/authentic-certificate.svg"
                />
              </div>
              <div className={styles.about_course_list_item_text}>
                <h6>Authentic Certificate</h6>
                <span>Earn a Certificate upon completion.</span>
              </div>
            </div>
            <div className={styles.about_course_list_item}>
              <div className={styles.about_course_list_item_icon}>
                <img
                  width={24}
                  height={24}
                  alt="list icon"
                  src="/images/icons/onsite-training.svg"
                />
              </div>
              <div className={styles.about_course_list_item_text}>
                <h6>Onsite training</h6>
                <span>Face to face training.</span>
              </div>
            </div>
            <div className={styles.about_course_list_item}>
              <div className={styles.about_course_list_item_icon}>
                <img
                  width={24}
                  height={24}
                  alt="list icon"
                  src="/images/icons/safe-spaces.svg"
                />
              </div>
              <div className={styles.about_course_list_item_text}>
                <h6>Safe spaces</h6>
                <span>Safe space for growth and learning.</span>
              </div>
            </div>
            <div className={styles.about_course_list_item}>
              <div className={styles.about_course_list_item_icon}>
                <img
                  width={24}
                  height={24}
                  alt="list icon"
                  src="/images/icons/beginner-level.svg"
                />
              </div>
              <div className={styles.about_course_list_item_text}>
                <h6>Beginner Level</h6>
                <span>No prior experience required.</span>
              </div>
            </div>

            <div>
              <span className={styles.btn_text}>
                Want to get full details about this course?
              </span>
              {course?.file && course?.file?.name !== "" && (
                <Button
                  type="secondary"
                  text="Download Syllabus"
                  onClick={() => downloadSyllabus()}
                  icon="/images/icons/download.svg"
                  customStyle={{ marginTop: "0.5em" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <Popup
          type={type}
          isOpen={isOpen}
          course={course.id}
          closeModal={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}

export default observer(AboutCourse);
