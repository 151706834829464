import React, { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "./editor.css";

interface TextEditorProps {
  text: string;
  setText: (text: string) => void;
  readOnly?: boolean;
}

const TextEditor: React.FC<TextEditorProps> = ({ text, setText, readOnly }) => {
  const modules = {
    toolbar: {
      container: [
        [{ font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        [{ align: [] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video", "formula", "code-block"],
        ["clean"],
      ],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "code-block",
    "formula",
  ];

  return (
    <div className="input_container">
      <ReactQuill
        value={text}
        onChange={setText}
        theme="snow"
        modules={modules}
        formats={formats}
        readOnly={readOnly}
      />
    </div>
  );
};

export default TextEditor;
