import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import styles from "./navbar.module.css";
import { Button } from "..";
import { useStore } from "../../../store";
import { observer } from "mobx-react";

interface IProps {
  isTransparent?: boolean;
}

const Navbar: React.FC<IProps> = ({ isTransparent }) => {
  const navigate = useNavigate();
  const { userStore } = useStore();

  let user = userStore.user;
  let role = user?.roles[0]?.name;
  let userRoles = user?.roles.map((role: any) => {
    return role.name;
  });
  const [dropdownItems, setDropdownItems] = useState<any[]>([
    { name: "My Profile", path: "/myprofile" },
  ]);

  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [userMenu, setUserMenu] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!user) {
      userStore.getUser().then(() => {
        user = userStore.user;
        role = user?.roles[0]?.name;
        userRoles = user?.roles.map((role: any) => {
          return role.name;
        });
        initDropDownItems();
      });
    } else {
      initDropDownItems();
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDropdownMenu]);

  const handleLogout = () => {
    userStore.logout();
    navigate("/");
  };

  const initDropDownItems = () => {
    let items = [{ name: "My Profile", path: "/myprofile" }];

    if (userRoles && userRoles.length > 0) {
      if (userRoles.includes("admin") || userRoles.includes("teacher")) {
        items.push(
          {
            name: "Teaching Sessions and Courses",
            path: "/teaching",
          },
          { name: "Learning Sessions", path: "/mysessions" }
        );
      }

      if (userRoles.includes("admin") || userRoles.includes("host")) {
        items.push({
          name: "Classrooms Management",
          path: "/classroomsManagement",
        });
      }

      items.push({ name: "Favorites", path: "/favorites" });

      setDropdownItems(items);
    }
  };

  return (
    <nav
      className={styles.navbar}
      style={isTransparent ? { backgroundColor: "transparent" } : {}}
    >
      <div className={styles.navbar_container}>
        <Link to="/">
          <img src="/images/logo.png" alt="Logo" width={145} height={30} />
        </Link>

        <div className={styles.navbar_links}>
          <Link to="/courses">Courses</Link>
          <a href="https://wemag.wecode.land/teach-with-us">Teach with us</a>
          <a href="https://wemag.wecode.land/list-your-space">
            List your space
          </a>
          <a href="http://wemag.wecode.land/about">About us</a>
        </div>

        {!user ? (
          <>
            <div className={styles.navbar_buttons}>
              <Link to="/login" className={styles.navbar_button}>
                Sign In
              </Link>
              <Button
                text="Create an account"
                onClick={() => navigate("/register")}
              />
            </div>

            <div className={styles.navbar_mobile}>
              <img
                src={
                  dropdown
                    ? "/images/icons/close.svg"
                    : "/images/icons/menu.svg"
                }
                alt="Logo"
                width={25}
                height={25}
                onClick={() => setDropdown(!dropdown)}
              />

              {dropdown && (
                <div className={styles.navbar_mobile_links}>
                  <Link to="/login">Sign In</Link>
                  <Link to="/register">Create an account</Link>
                  <hr />
                  <Link to="/courses">Courses</Link>
                  <a href="https://wemag.wecode.land/teach-with-us">
                    Teach with us
                  </a>
                  <a href="https://wemag.wecode.land/list-your-space">
                    List your space
                  </a>
                  <a href="http://wemag.wecode.land/about">About us</a>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              className={styles.navbar_user}
              onClick={() => setDropdownMenu(!dropdownMenu)}
              ref={dropdownRef}
            >
              <img
                className={styles.navbar_avatar}
                src="/images/default-user.svg"
                alt="Avatar"
              />
              <span className={styles.username}>{user.fullName}</span>
              <img
                src={
                  dropdownMenu
                    ? "/images/icons/arrow-up.svg"
                    : "/images/icons/arrow-down.svg"
                }
                alt=""
              />

              {dropdownMenu && (
                <div className={styles.navbar_user_links}>
                  {dropdownItems.map((item, index) => (
                    <React.Fragment key={index}>
                      <Link className={styles.link} to={item.path}>
                        {item.name}
                      </Link>
                      <hr />
                    </React.Fragment>
                  ))}
                  <span
                    className={styles.link}
                    onClick={handleLogout}
                    style={{ cursor: "pointer" }}
                  >
                    Logout
                  </span>
                </div>
              )}
            </div>

            <div className={styles.navbar_mobile}>
              <img
                src={
                  dropdown
                    ? "/images/icons/close.svg"
                    : "/images/icons/menu.svg"
                }
                alt="Logo"
                width={25}
                height={25}
                onClick={() => setDropdown(!dropdown)}
              />

              {dropdown && (
                <div className={styles.navbar_mobile_links}>
                  <span
                    className={styles.username}
                    onClick={() => setUserMenu(!userMenu)}
                  >
                    {user.fullName}
                  </span>
                  <hr />
                  {userMenu && (
                    <>
                      {dropdownItems.map((item, index) => (
                        <Link
                          className={styles.link}
                          key={index}
                          to={item.path}
                        >
                          {item.name}
                        </Link>
                      ))}
                      <hr />
                    </>
                  )}
                  <Link to="/courses">Courses</Link>
                  <a href="https://wemag.wecode.land/teach-with-us">
                    Teach with us
                  </a>
                  <a href="https://wemag.wecode.land/list-your-space">
                    List your space
                  </a>
                  <a href="http://wemag.wecode.land/about">About us</a>
                  <hr />
                  <span onClick={handleLogout} style={{ cursor: "pointer" }}>
                    Logout
                  </span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default observer(Navbar);
