import { observer } from "mobx-react";

import Slider from "react-slick";
import env from "../../../../config/env";
import { useStore } from "../../../../store";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./classrooms.css";

function CourseClassrooms() {
  const { courseStore } = useStore();
  const course: any = courseStore.courseDetails;

  const classrooms = course?.sessionBooking
    ?.map((classroom: any) => {
      if (!classroom?.bookingRoom?.isOnline) {
        return {
          logo: classroom?.bookingRoom?.logo,
          name: classroom?.bookingRoom?.name,
          address: classroom?.bookingRoom?.address,
        };
      }
      return null;
    })
    .filter((classroom: any) => classroom !== null);

  var settings = {
    dots: true,
    infinite: classrooms?.length > 1,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      {classrooms?.length > 0 && (
        <div className="course_classrooms" id="classrooms">
          <div className="course_classrooms_content">
            <h3 className="course_classrooms_title">Classrooms</h3>
            <div className="slick_container">
              <Slider {...settings}>
                {classrooms?.map((classroom: any, index: number) => (
                  <div key={index}>
                    <img
                      className="classroom_image"
                      src={
                        classroom?.logo &&
                        Object.keys(classroom?.logo).length > 0
                          ? `${env.IMAGE_API}${classroom?.logo?.name}`
                          : "images/course-default.png"
                      }
                      alt="classroom"
                    />
                    <div className="classroom_details">
                      <h2>{classroom?.name}</h2>
                      <div className="location">
                        <img
                          width={20}
                          height={20}
                          alt="location"
                          src="/images/icons/location-gray.svg"
                        />
                        <span>{classroom?.address?.name}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(CourseClassrooms);
