import React from "react";

import styles from "./input.module.css";

interface InputProps {
  fullWidth?: boolean;
  label?: string;
  value: string;
  type?: "text" | "password" | "number";
  readOnly?: boolean;
  required?: boolean;
  validation?: (value: string) => boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({
  fullWidth,
  label,
  value,
  type,
  readOnly,
  required,
  validation,
  onChange,
}) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [inputType, setInputType] = React.useState<string>(type || "text");
  const handleTypeChange = () => {
    if (type == "password")
      showPassword ? setInputType("password") : setInputType("text");
    setShowPassword((prev) => !prev);
  };

  return (
    <div className={styles.input_container}>
      <div className={styles.password_field}>
        <input
          value={value}
          readOnly={readOnly}
          required={required}
          onChange={onChange}
          placeholder={label}
          type={inputType}
          className={styles.input}
          onFocus={() => setIsFocused(true)}
          style={fullWidth ? { flex: 1 } : {}}
        />
        {type === "password" && (
          <img
            className={styles.password_icon}
            src={showPassword ? "/images/visible.png" : "/images/invisible.png"}
            alt="eye"
            onClick={handleTypeChange}
          />
        )}
      </div>

      {isFocused &&
        validation &&
        !validation(value) &&
        label !== "Confirm Password" && (
          <span className={styles.error}>Invalid {label}</span>
        )}
      {isFocused &&
        validation &&
        !validation(value) &&
        label === "Confirm Password" && (
          <span className={styles.error}>
            Password and confirm password don't match
          </span>
        )}
    </div>
  );
};

export default Input;
