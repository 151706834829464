import { useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import styles from "./navbar.module.css";
import { Button, Popup } from "../../../../components";
import { useStore } from "../../../../store";

interface IProps {
  type: "course" | "cohort";
}

function CourseNavBar({ type }: IProps) {
  const navigate = useNavigate();
  const { courseStore, userStore } = useStore();

  const token = localStorage.getItem("token");

  const [active, setActive] = useState<number>(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  let course: any = courseStore.courseDetails;
  if (type === "cohort") {
    course = course?.sessionCourse;
  }
  const stories: any = course?.testimonials || [];
  const goals: any = course?.objectifs?.image?.name;

  const onClick = (index: number, link: string) => {
    setActive(index);
    const element = document.getElementById(link);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const participate = async () => {
    courseStore.selectCourse(course.id);

    if (userStore.user) {
      navigate(`/payment/${course.id}`);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className={styles.course_navbar}>
      <div className={styles.course_navbar_content}>
        <div className={styles.course_navbar_links}>
          <div
            onClick={() => onClick(1, "about-course")}
            className={`
                            ${styles.course_navbar_item}
                            ${
                              active === 1
                                ? styles.course_navbar_item_active
                                : ""
                            }
                        `}
          >
            <span>About Course</span>
          </div>

          <div
            onClick={() => onClick(2, "course-content")}
            className={`
                            ${styles.course_navbar_item}
                            ${
                              active === 2
                                ? styles.course_navbar_item_active
                                : ""
                            }
                        `}
          >
            <span>Course Content</span>
          </div>

          {course?.tutors && token && (
            <div
              onClick={() => onClick(3, "about-instructor")}
              className={`
                                ${styles.course_navbar_item}
                                ${
                                  active === 3
                                    ? styles.course_navbar_item_active
                                    : ""
                                }
                            `}
            >
              <span>About {type === "cohort" ? "instructor" : "creator"}</span>
            </div>
          )}

          {type === "cohort" && (
            <div
              onClick={() => onClick(4, "schedule")}
              className={`
                                ${styles.course_navbar_item}
                                ${
                                  active === 4
                                    ? styles.course_navbar_item_active
                                    : ""
                                }
                            `}
            >
              <span>Timetable</span>
            </div>
          )}

          {type === "cohort" && goals && (
            <div
              onClick={() => onClick(5, "goals-prospects")}
              className={`
                                ${styles.course_navbar_item}
                                ${
                                  active === 5
                                    ? styles.course_navbar_item_active
                                    : ""
                                }
                            `}
            >
              <span>Goals & Perspectives</span>
            </div>
          )}

          {type === "course" && (
            <div
              onClick={() => onClick(8, "upcoming-cohorts")}
              className={`
                            ${styles.course_navbar_item}
                            ${
                              active === 8
                                ? styles.course_navbar_item_active
                                : ""
                            }
                        `}
            >
              <span>Upcoming Cohorts</span>
            </div>
          )}

          {stories.length > 0 && stories[0].author?.length > 0 && (
            <div
              onClick={() => onClick(6, "success-stories")}
              className={`
                                ${styles.course_navbar_item}
                                ${
                                  active === 6
                                    ? styles.course_navbar_item_active
                                    : ""
                                }
                            `}
            >
              <span>Succcess Stories</span>
            </div>
          )}

          <div
            onClick={() => onClick(7, "faqs")}
            className={`
                            ${styles.course_navbar_item}
                            ${
                              active === 7
                                ? styles.course_navbar_item_active
                                : ""
                            }
                        `}
          >
            <span>FAQs</span>
          </div>
        </div>

        <Button
          type="primary"
          text="Participate"
          width="170px"
          onClick={() => participate()}
        />
      </div>

      {isOpen && (
        <Popup
          type={type}
          isOpen={isOpen}
          course={course.id}
          closeModal={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}

export default observer(CourseNavBar);
