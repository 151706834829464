import { observer } from "mobx-react";

import styles from "./content.module.css";
import ContentItem from "./ContentItem";
import { useStore } from "../../../../store";
import { useState } from "react";

function CourseContent() {
    const { courseStore } = useStore();

    const [expandAll, setExpandAll] = useState(false);

    const course : any = courseStore.courseDetails;

    return (
        <div className={styles.course_content} id="course-content">
            <div className={styles.course_content_content}>
                <div className={styles.course_content_info}>
                    <h4 className={styles.course_content_subtitle}>Course Content</h4>
                    <h3 className={styles.course_content_title}>Our courses incorporate a variety of learning materials, including videos, articles, and exams.</h3>
                </div>

                <div className={styles.course_summary}>
                    <div className={styles.numbers}>
                        {/* {course?.syllabus?.blocks?.length} Lessons
                        <img src="/images/icons/dot.svg" alt="dot" /> 
                        {course?.syllabus?.blocks?.length} Sessions
                        <img src="/images/icons/dot.svg" alt="dot" /> 
                        {course?.syllabus?.blocks?.length} Articles
                        <img src="/images/icons/dot.svg" alt="dot" /> 
                        {course?.syllabus?.blocks?.length} Assignments
                        <img src="/images/icons/dot.svg" alt="dot" /> 
                        {course?.syllabus?.blocks?.length}h Compition Time */}
                    </div>

                    <div className={styles.expand_all} onClick={() => setExpandAll(!expandAll)}>
                        {expandAll ? "Collapse" : "Expand"} All Lessons
                    </div>
                </div>

                <div className={styles.course_content_items}>
                    {course?.syllabus?.blocks?.map((item: any, index: number) => (
                        item.type === "unstyled" ?
                            <ContentItem 
                                key={index}
                                expanded={expandAll}
                                course={item} />
                        : null
                    ))}
                </div>
            </div>
        </div>
    );
}

export default observer(CourseContent);