import { useState } from "react";
import styles from "./goals.module.css";
import { Button, Popup } from "../../../../components";
import { observer } from "mobx-react";
import { useStore } from "../../../../store";
import env from "../../../../config/env";
import { useNavigate } from "react-router-dom";

interface IProps {
  type: "course" | "cohort";
}

function AboutInstructor({ type }: IProps) {
  const navigate = useNavigate();
  const { courseStore, userStore } = useStore();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const course: any = courseStore.courseDetails;
  const objectifs: any =
    type === "course" ? course?.objectifs : course?.sessionCourse?.objectifs;
  const perspectives: any =
    type === "course"
      ? course?.perspectives
      : course?.sessionCourse?.perspectives;

  const participate = async () => {
    courseStore.selectCourse(course.id);

    if (userStore.user) {
      await courseStore.lookForSessions(course.id);
      navigate("/search");
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className={styles.course_goals} id="goals-prospects">
      <div className={styles.course_goals_content}>
        {objectifs?.image?.name ? (
          <div className={styles.course_goals_item}>
            <div className={styles.course_goals_details}>
              {/* <h3>{objectifs?.content?.blocks[0]?.text}</h3> */}
              <p className="text-[#4B444D] font-inter font-bold text-3xl">
                Goals
              </p>
              <div className="text-[#1C1D1F] font-intet text-[17px]">
                <p>{objectifs?.content?.blocks[0]?.text}</p>
                <p>{objectifs?.content?.blocks[1]?.text}</p>
              </div>
              <div className="w-full md:w-1/4">
                <Button
                  type="primary"
                  text="Start now"
                  onClick={() => participate()}
                />
              </div>
            </div>

            <img
              width={519}
              height={360}
              alt="goals"
              src={`${env.IMAGE_API}${objectifs?.image?.name}`}
            />
          </div>
        ) : null}

        {perspectives?.image?.name ? (
          <div className={styles.course_goals_item}>
            <img
              width={519}
              height={360}
              alt="goals"
              src={`${env.IMAGE_API}${perspectives.image.name}`}
            />

            <div className={styles.course_goals_details}>
              {/* <h3>{perspectives?.content?.blocks[0]?.text}</h3> */}
              <p className="text-[#4B444D] font-inter font-bold text-3xl">
                prospects
              </p>
              <p>{perspectives?.content?.blocks[0]?.text}</p>
              {/* <p>{block?.text}</p> */}
              <div className="w-full md:w-1/4">
                <Button
                  type="primary"
                  text="Start now"
                  onClick={() => participate()}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <Popup
        type="course"
        isOpen={isOpen}
        course={course.id}
        closeModal={() => setIsOpen(false)}
      />
    </div>
  );
}

export default observer(AboutInstructor);
